<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->

<template>
    <v-container fluid>
      <new-edit-sheet />
      <delete-dialog />
      <v-snackbar v-model="copyNotice">
        API Key copied to clipboard
        <v-btn color="info" text @click="copyNotice = false">Close</v-btn>
      </v-snackbar>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col cols="8">
          <settings-breadcrumbs v-model="project" />
        </v-col>
        <v-col class="text-right">
          <v-btn color="info" class="mr-2" @click="generateApiKey">
            <v-icon class="mr-1">mdi-key-plus</v-icon>
            New API Key
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-alert outlined dismissible icon="mdi-key" prominent type="info">
            <div>
              <h3>API Key Management</h3>
              <p>Manage your API keys here to authenticate requests to the UnStruct API. API keys serve as a secure method for identifying your application and verifying its permissions to access specific API functionalities.</p>
              <ul>
                <li><strong>Create, Edit, or Delete:</strong> Easily manage your API keys through the UnStruct platform to fit your application's needs.</li>
                <li><strong>Authentication:</strong> Use API keys to authenticate requests, ensuring that your application can securely communicate with UnStruct's API.</li>
                <li><strong>Permissions:</strong> API keys control access levels, allowing you to define and restrict what actions your application can perform within the UnStruct ecosystem.</li>
              </ul>
              <br>
              <p>For security reasons, treat your API keys as confidential information. Do not share them publicly or expose them in client-side code where they can be easily accessed.</p>
            </div>
          </v-alert>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-card elevation="0">
            <v-card-title>
              <v-text-field
                v-model="q"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                clearable
              />
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="items"
              :server-items-length="total"
              :page.sync="page"
              :items-per-page.sync="itemsPerPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="descending"
              :loading="loading"
              loading-text="Loading... Please wait"
            >
            <template v-slot:item.key="{ item }">
              <div v-if="showKeys[item.id]">
                {{ item.key }}
                <v-btn small icon @click="toggleKeyVisibility(item)">
                  <v-icon>mdi-eye-off</v-icon>
                </v-btn>
              </div>
              <div v-else>
                **********
                <v-btn small icon @click="toggleKeyVisibility(item)">
                  <v-icon>mdi-eye-check</v-icon>
                </v-btn>
                <v-btn small icon @click="copyKey(item.key)">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </div>
            </template>
              <template v-slot:item.expiration="{ item }">
                <div>{{ item.expiration }}</div>
              </template>
              <template v-slot:item.data-table-actions="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="createEditShow(item)">
                      <v-list-item-title>View / Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="removeShow(item)">
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>

  <script>
  import { mapFields } from "vuex-map-fields"
  import { mapActions } from "vuex"
  import RouterUtils from "@/router/utils"

  import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"
  import DeleteDialog from "@/apiKeys/DeleteDialog.vue"
  import NewEditSheet from "@/apiKeys/NewEditSheet.vue"

  export default {
    name: "ApiKeyTable",
    components: {
      DeleteDialog,
      NewEditSheet,
      SettingsBreadcrumbs,
    },
    data() {
      return {
        headers: [
          { text: "Key", value: "key", sortable: true },
          { text: "Expiration", value: "expiration", sortable: true },
          { text: "Description", value: "description", sortable: true },
          { text: "", value: "data-table-actions", sortable: false, align: "end" },
        ],
        showKeys: {},
        copyNotice: false,
      }
    },
    computed: {
      ...mapFields("apiKeys", [
        "table.options.q",
        "table.options.page",
        "table.options.itemsPerPage",
        "table.options.sortBy",
        "table.options.descending",
        "table.options.filters.project",
        "table.loading",
        "table.rows.items",
        "table.rows.total",
      ]),
      ...mapFields("route", ["query"]),
      defaultUserProjects: {
        get() {
          let d = null
          if (this.projects) {
            d = this.projects.filter((v) => v.default === true)
            return d.map((v) => v.project)
          }
          return d
        },
      },
    },
    created() {
      this.filters = {
        ...this.filters,
        ...RouterUtils.deserializeFilters(this.query),
        project: this.defaultUserProjects,
      }

      if (this.query.project) {
        this.project = [{ name: this.query.project }]
      }

      this.getAll()
      this.$watch(
        (vm) => [vm.page],
        () => {
          this.getAll()
        }
      )

      this.$watch(
        (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
        () => {
          this.page = 1
          this.$router.push({ query: { project: this.project[0].name } })
          this.getAll()
        }
      )
    },
    methods: {
      toggleKeyVisibility(item) {
      this.$set(this.showKeys, item.id, !this.showKeys[item.id]);
      },
      copyKey(key) {
        navigator.clipboard.writeText(key).then(() => {
          this.copyNotice = true;
          setTimeout(() => {
            this.copyNotice = false;
          }, 3000); // Hide the notice after 3 seconds
        }).catch(err => {
          console.error('Could not copy text: ', err);
        });
      },
      ...mapActions("apiKeys", ["getAll", "createEditShow", "removeShow"]),
      generateApiKey() {
        const payload = {
          description: 'New API Key',
          project: this.project[0],
          expiration: null,
        };
        this.$store.dispatch('apiKeys/generateApiKey', payload);
      },
    },
  }
  </script>
